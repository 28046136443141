<template>
  <div>
    <a-row :gutter="[20, 0]">
      <a-col :span="24">
        <h3 class="title">
          <a-row>
            <a-col :span="8"> HOTEL {{ hotelID }}</a-col>
            <a-col class="a-right" :span="8" :offset="8">
              <a-button
                v-if="add && totalRows < 10"
                size="small"
                @click="addHotel"
                type="link"
              >
                Adicionar hospedagem
              </a-button>

              <a-button
                class="red"
                v-if="id == totalRows && totalRows > 1"
                size="small"
                @click="removeHotel"
                type="link"
              >
                Remover
              </a-button>
            </a-col>
          </a-row>
        </h3>
      </a-col>

      <a-col :span="10">
        <a-form-item>
          <label
            :class="
              this.form.getFieldValue('hotel_' + hotelID + '_name')
                ? 'filled'
                : 'filled'
            "
            >Hotel / Resort
            <font
              v-if="
                tempContract[`hotel_${hotelID}_name_infotera`] &&
                !tempContract[`hotel_${hotelID}_name`]
              "
              class="cgray"
              style="font-size: 9px"
            >
              -
              {{ tempContract[`hotel_${hotelID}_name_infotera`] }}
            </font>
          </label>

          <a-select
            class="travel-input"
            show-search
            :allow-clear="true"
            placeholder="Selecione o Hotel ou Resort"
            optionFilterProp="txt"
            @change="onHotelSelect"
            v-decorator="[
              `hotel_${hotelID}_name`,
              {
                rules: [
                  {
                    required: dynamicRequiredField(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(item, index) of productSupplier.list"
              :key="index != undefined ? index : 0"
              :txt="item.trading_name != undefined ? item.trading_name : ''"
              :value="item.trading_name != undefined ? item.trading_name : ''"
            >
              {{ item.trading_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="5">
        <a-form-item>
          <label
            :class="
              this.form.getFieldValue(`hotel_${hotelID}_checkin`)
                ? 'filled'
                : ''
            "
            >Check-in</label
          >
          <a-date-picker
            class="travel-input"
            placeholder="Check-in"
            format="DD/MM/YYYY"
            :disabled-date="minDate"
            :showToday="false"
            :allowClear="false"
            v-mask="'##/##/####'"
            @change="selectedDate"
            v-decorator="[
              'hotel_' + hotelID + '_checkin',
              {
                rules: [
                  {
                    required: dynamicRequiredField(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-date-picker>
        </a-form-item>
      </a-col>
      <a-col :span="5">
        <a-form-item>
          <label
            :class="
              this.form.getFieldValue(`hotel_${hotelID}_checkout`)
                ? 'filled'
                : ''
            "
            >Check-out</label
          >
          <a-date-picker
            class="travel-input"
            placeholder="Check-out"
            format="DD/MM/YYYY"
            :showToday="false"
            :allowClear="false"
            :disabled-date="disabledEndDate"
            :open="checkoutOpen"
            @openChange="checkoutOpenChange"
            @change="calcDaysInHotel"
            v-mask="'##/##/####'"
            v-decorator="[
              'hotel_' + hotelID + '_checkout',
              {
                rules: [
                  {
                    required: dynamicRequiredField(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-date-picker>
        </a-form-item>
      </a-col>

      <a-col :span="4">
        <a-form-item>
          <label
            :class="
              this.form.getFieldValue(`hotel_${hotelID}_rates`) ? 'filled' : ''
            "
            >Diárias</label
          >
          <a-input
            type="number"
            class="travel-input"
            placeholder="Diárias"
            v-decorator="[
              'hotel_' + hotelID + '_rates',
              {
                rules: [
                  {
                    required: dynamicRequiredField(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="5">
        <a-form-item>
          <label
            :class="
              this.form.getFieldValue(`hotel_${hotelID}_diet`)
                ? 'filled'
                : 'filled'
            "
            >Regime alimentar
            <font
              v-if="
                tempContract[`hotel_${hotelID}_diet_infotera`] &&
                !tempContract[`hotel_${hotelID}_diet`]
              "
              class="cgray"
              style="font-size: 9px"
            >
              -
              {{ tempContract[`hotel_${hotelID}_diet_infotera`] }}
            </font></label
          >

          <a-select
            class="travel-input"
            show-search
            placeholder="Regime alimentar"
            v-decorator="[
              `hotel_${hotelID}_diet`,
              {
                rules: [
                  {
                    required: dynamicRequiredField(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(item, index) of diets"
              :key="index"
              :value="item.value"
            >
              {{ item.name.toUpperCase() }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="5">
        <a-form-item>
          <label
            :class="
              this.form.getFieldValue(`hotel_${hotelID}_locator`)
                ? 'filled'
                : ''
            "
            >Localizador</label
          >
          <a-input
            class="travel-input"
            placeholder="Localizador"
            v-decorator="[
              'hotel_' + hotelID + '_locator',
              {
                rules: [
                  {
                    required: dynamicRequiredField(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="5">
        <a-form-item>
          <label
            :class="
              this.form.getFieldValue(`hotel_${hotelID}_total_price`)
                ? 'filled'
                : ''
            "
            >Valor total (R$)
          </label>
          <a-input
            class="travel-input"
            placeholder="Valor total"
            v-currency
            v-decorator="[
              'hotel_' + hotelID + '_total_price',
              {
                rules: [
                  {
                    required: dynamicRequiredField(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            @blur="emitValueUpdate"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row
      v-for="accommodation in accommodationRows"
      :key="accommodation.id"
      :class="`accommodation accommodation-${accommodation.id}`"
      :gutter="[15, 0]"
    >
      <a-col :span="$root.windowWidth > 900 ? 10 : 24"
        ><a-col :span="24">
          <h4 class="title">
            <a-row>
              <a-col class="text" :span="12">
                Acomodação #{{ accommodation.id }}
              </a-col>
              <a-col class="a-right" :span="12">
                <a-button
                  v-if="accommodationRows.length < 5 && accommodation.id === 1"
                  size="small"
                  @click="addAccommodation(accommodationRows.length)"
                  type="link"
                >
                  Adicionar
                </a-button>
                <a-button
                  class="red"
                  v-if="
                    accommodation.id === accommodationRows.length &&
                    accommodationRows.length > 1
                  "
                  size="small"
                  @click="removeAccommodation(accommodation.id)"
                  type="link"
                >
                  Remover
                </a-button>
              </a-col>
            </a-row>
          </h4>
        </a-col>
        <a-col :span="14">
          <a-form-item>
            <label
              :class="
                form.getFieldValue(
                  'hotel_' + hotelID + '_accommodation_' + accommodation.id
                )
                  ? 'filled'
                  : ''
              "
              >Quarto</label
            >
            <a-input
              class="travel-input"
              placeholder="Quarto"
              v-decorator="[
                'hotel_' + hotelID + '_accommodation_' + accommodation.id,

                {
                  rules: [
                    {
                      required: dynamicRequiredField(),
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="10">
          <a-form-item>
            <label
              :class="
                form.getFieldValue(
                  'hotel_' +
                    hotelID +
                    '_accommodation_' +
                    accommodation.id +
                    '_type'
                )
                  ? 'filled'
                  : ''
              "
              >Tipo</label
            >

            <a-select
              class="travel-input"
              show-search
              placeholder="Tipo"
              v-decorator="[
                'hotel_' +
                  hotelID +
                  '_accommodation_' +
                  accommodation.id +
                  '_type',
                {
                  rules: [
                    {
                      required: dynamicRequiredField(),
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-select-option
                v-for="(item, index) of accommodationTypes"
                :key="index"
                :value="item.value"
              >
                {{ item.name.toUpperCase() }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-col>

      <a-col :span="$root.windowWidth > 900 ? 14 : 24">
        <a-row>
          <a-col :span="24">
            <h4 class="title">
              <a-row>
                <a-col :span="12"> Viajantes </a-col>
                <a-col class="a-right" :span="12">
                  <a-button
                    v-if="
                      accommodationRows[accommodation.id - 1].travellers
                        .length < 10
                    "
                    size="small"
                    @click="
                      addTraveller(
                        accommodationRows[accommodation.id - 1].travellers
                          .length,
                        accommodation.id
                      )
                    "
                    type="link"
                  >
                    Adicionar
                  </a-button>
                </a-col>
              </a-row>
            </h4>
          </a-col>
        </a-row>

        <a-row
          v-for="(traveller, index) in accommodationRows[accommodation.id - 1]
            .travellers"
          :key="index"
          :gutter="20"
          class="travellers-rows"
        >
          <a-col :span="2">
            <font class="user">
              <a-icon type="user" />
              <font class="number">
                {{ traveller.id }}
              </font>
            </font>
          </a-col>

          <a-col :span="20">
            <a-form-item class="travel-input">
              <a-select
                placeholder="Selecione um viajante"
                v-decorator="[
                  `hotel_${hotelID}_accommodation_${accommodation.id}_traveller_${traveller.id}`,
                  {
                    rules: [
                      {
                        required: dynamicRequiredField(),
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="(traveller, index) of theTravellers"
                  :key="index"
                  :value="traveller.id"
                >
                  {{ traveller.name }}
                  {{ traveller.lastName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="1">
            <a-button
              v-if="
                traveller.id ==
                  accommodationRows[accommodation.id - 1].travellers.length &&
                accommodationRows[accommodation.id - 1].travellers.length != 1
              "
              size="small"
              type="danger"
              shape="circle"
              icon="delete"
              @click="removeTraveller(traveller.id, accommodation.id)"
            >
            </a-button>

            <a-tooltip placement="topRight" title="Adicionar todos viajantes">
              <a-button
                v-if="traveller.id == 1"
                size="small"
                type="primary"
                shape="circle"
                @click="addAllTravellers(accommodation.id)"
                style="top: 5px"
              >
                <a-icon type="usergroup-add" style="font-size: 17px" />
              </a-button>
            </a-tooltip>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { differenceInDays, parse, format } from "date-fns";
import hotelMixins from "@/mixins/hotel/hotelMixins";
import productSupplierMixins from "@/mixins/product-suppliers/productSupplierMixins.js";

export default {
  name: "HotelForm",
  mixins: [hotelMixins, productSupplierMixins],
  props: {
    id: Number,
    add: Boolean,
    totalRows: Number,
    hotelRows: Array,
    tempContract: Object,
    form: Object,
    hotel: Object,
    theTravellers: Array,
  },
  data() {
    return {
      hotelID: this.hotelRows[this.id - 1].id,
      hotelIndex: this.id - 1,
      accommodationRows: this.hotelRows[this.id - 1].accommodation,
      checkoutOpen: false,
      diets: [
        { name: "ALL INCLUSIVE", value: "ALL INCLUSIVE" },
        { name: "CAFÉ DA MANHÃ", value: "CAFÉ DA MANHÃ" },
        { name: "MEIA PENSAO", value: "MEIA PENSAO" },
        { name: "PENSÃO COMPLETA", value: "PENSÃO COMPLETA" },
        { name: "NÃO TEM", value: "NÃO TEM" },
      ],
      accommodationTypes: [
        { name: "SINGLE", value: "SINGLE" },
        { name: "DUPLO", value: "DUPLO" },
        { name: "TRIPLO", value: "TRIPLO" },
        { name: "QUADRUPLO", value: "QUADRUPLO" },
        { name: "QUINTUPLO", value: "QUINTUPLO" },
      ],
    };
  },
  beforeMount() {
    this.productSupplier.filters.order = "ascend";
    this.productSupplier.filters.orderBy = "trading_name";
    this.productSupplier.filters.status.selected = ["Ativo"];
    this.productSupplier.pagination.perPage = 400;
    this.getProductSuppliers();

    setTimeout(() => {
      this.calcDaysOnLoad();
      this.disabledEndDate();
      this.form.setFieldsValue({
        [`hotel_${this.hotelID}_name`]:
          this.tempContract[`hotel_${this.hotelID}_name`],
        [`hotel_${this.hotelID}_checkin`]:
          this.tempContract[`hotel_${this.hotelID}_checkin`],
        [`hotel_${this.hotelID}_checkout`]:
          this.tempContract[`hotel_${this.hotelID}_checkout`],
        [`hotel_${this.hotelID}_rates`]:
          this.tempContract[`hotel_${this.hotelID}_rates`],
        [`hotel_${this.hotelID}_locator`]:
          this.tempContract[`hotel_${this.hotelID}_locator`],
        [`hotel_${this.hotelID}_diet`]:
          this.tempContract[`hotel_${this.hotelID}_diet`],
        [`hotel_${this.hotelID}_total_price`]:
          this.tempContract[`hotel_${this.hotelID}_total_price`],
      });

      this.hotel.accommodation.forEach((room) => {
        this.form.setFieldsValue({
          [`hotel_${this.hotelID}_accommodation_${room.id}`]:
            this.tempContract[`hotel_${this.hotelID}_accommodation_${room.id}`],
          [`hotel_${this.hotelID}_accommodation_${room.id}_type`]:
            this.tempContract[
              `hotel_${this.hotelID}_accommodation_${room.id}_type`
            ],
        });
        room.travellers.forEach((traveller) => {
          this.form.setFieldsValue({
            [`hotel_${this.hotelID}_accommodation_${room.id}_traveller_${traveller.id}`]:
              this.tempContract[
                `hotel_${this.hotelID}_accommodation_${room.id}_traveller_${traveller.id}`
              ] != undefined
                ? parseInt(
                    this.tempContract[
                      `hotel_${this.hotelID}_accommodation_${room.id}_traveller_${traveller.id}`
                    ]
                  )
                : undefined,
          });
        });
      });
    });
  },
  methods: {
    removeHotel() {
      this.$emit("removeRows", this.id);
    },
    addHotel() {
      this.$emit("updateRows", this.hotelRows.length);
    },
    addAccommodation() {
      for (let i in this.hotelRows) {
        if (this.hotelRows[i].id == this.hotelID) {
          this.hotelRows[i].accommodation.push({
            id: this.hotelRows[i].accommodation.length + 1,
            travellers: [
              {
                id: 1,
              },
            ],
          });
          break;
        }
      }
      this.$emit("updateHotelRows", this.hotelRows);
    },
    removeAccommodation(id) {
      for (var i in this.accommodationRows) {
        if (this.accommodationRows[i].id == id) {
          this.accommodationRows.splice(i, 1);
          break;
        }
      }
      this.$emit("updateHotelRows", this.hotelRows);
    },
    emitValueUpdate() {
      this.$emit("emitValueUpdate");
    },
    getPayingTravellerData() {
      this.form.setFieldsValue({
        [`hotel_${this.hotelID}_accommodation_${1}_person_first_name_${1}`]:
          this.form.getFieldValue(`client_name`).split(" ")[0],
        [`hotel_${this.hotelID}_accommodation_${1}_person_last_name_${1}`]:
          this.form
            .getFieldValue(`client_name`)
            .substr(this.form.getFieldValue(`client_name`).indexOf(" ") + 1),
        [`hotel_${this.hotelID}_accommodation_${1}_person_birthday_${1}`]:
          this.form.getFieldValue(`client_birthday`),
        [`hotel_${this.hotelID}_accommodation_${1}_person_CPF_${1}`]:
          this.form.getFieldValue(`client_CPF`),
      });
    },
    addTraveller(qtdTravellers, accommodationID) {
      for (let i in this.accommodationRows) {
        if (this.accommodationRows[i].id == accommodationID) {
          this.accommodationRows[i].travellers.push({
            id: qtdTravellers + 1,
          });
          break;
        }
      }

      //this.tempContract["hotel_rows"] = this.hotelRows;
      this.$emit("updateHotelRows", this.hotelRows);
    },
    removeTraveller(travellerID, accommodationID) {
      for (let i in this.accommodationRows) {
        if (this.accommodationRows[i].id == accommodationID) {
          for (let j in this.accommodationRows[i].travellers) {
            if (this.accommodationRows[i].travellers[j].id == travellerID) {
              this.accommodationRows[i].travellers.splice(j, 1);
              break;
            }
          }
        }
      }

      // this.tempContract["hotel_rows"] = this.hotelRows;

      this.$emit("updateHotelRows", this.hotelRows);
    },
    maxDateAge(current) {
      return current && current > new Date();
    },
    minDate(current) {
      return current && current < new Date();
    },
    minDateOnLoad() {
      let startValue = parse(
        this.form.getFieldValue(`hotel_${this.hotelID}_checkin`),
        "yyyyMMdd",
        new Date()
      );
      return startValue;
    },
    disabledEndDate(endValue) {
      let startValue = this.form.getFieldValue(`hotel_${this.hotelID}_checkin`);

      if (endValue != undefined) {
        if (endValue["_d"] == undefined) {
          if (!endValue || !startValue) {
            return false;
          }

          return startValue.valueOf() >= endValue.valueOf();
        } else {
          if (!endValue || !startValue) {
            return false;
          }

          return startValue.valueOf() >= endValue.valueOf();
        }
      }
    },
    calcDaysOnLoad() {
      let startValue = parse(
        this.form.getFieldValue(`hotel_${this.hotelID}_checkin`),
        "yyyyMMdd",
        new Date()
      );

      let endValue = parse(
        this.form.getFieldValue(`hotel_${this.hotelID}_checkout`),
        "yyyyMMdd",
        new Date()
      );

      let daysInHotel = differenceInDays(endValue, startValue);

      if (daysInHotel) {
        this.form.setFieldsValue({
          [`hotel_${this.hotelID}_rates`]: daysInHotel,
        });
      }
    },
    selectedDate(current) {
      setTimeout(() => {
        this.form.setFieldsValue({
          [`hotel_${this.hotelID}_checkout`]: format(current._d, "MM/dd/yyyy"),
        });
        this.checkoutOpen = true;
      }, 100);
    },
    calcDaysInHotel(endValue) {
      this.checkoutOpen = false;
      let startValue = this.form.getFieldValue(`hotel_${this.hotelID}_checkin`);
      let daysInHotel = endValue
        ? differenceInDays(endValue._d, startValue._d)
        : "";

      this.form.setFieldsValue({
        [`hotel_${this.hotelID}_rates`]: daysInHotel + 1,
      });
    },
    checkoutOpenChange(open) {
      let checkout = this.form.getFieldValue(`hotel_${this.hotelID}_checkout`);
      this.disabledEndDate(checkout);
      this.checkoutOpen = open;
    },
    onHotelSelect(val) {
      if (val != undefined) {
        const a = this.productSupplier.list.filter((hotel) => {
          return hotel.trading_name === val;
        });
        this.tempContract[`hotel_${this.hotelID}_id`] = a[0].id;
        // this.form.setFieldsValue({
        //     [`hotel_${this.hotelID}_diet`]: a[0].diet.toUpperCase(),
        // });
      } else {
        this.tempContract[`hotel_${this.hotelID}_id`] = "";
        // this.form.setFieldsValue({
        //     [`hotel_${this.hotelID}_diet`]: undefined,
        // });
      }
    },
    addAllTravellers(accommodationID) {
      let travellers = [];

      this.theTravellers.forEach((traveller) => {
        if (traveller.name && traveller.lastName) {
          travellers.push({ id: traveller.id });
        }
      });

      if (travellers.length > 0) {
        for (let i in this.accommodationRows) {
          if (this.accommodationRows[i].id == accommodationID) {
            this.accommodationRows[i].travellers = travellers;
            break;
          }
        }

        setTimeout(() => {
          this.theTravellers.forEach((traveller) => {
            this.form.setFieldsValue({
              [`hotel_${this.hotelID}_accommodation_${accommodationID}_traveller_${traveller.id}`]:
                traveller.id,
            });
          });
        }, 10);

        this.$emit("updateHotelRows", this.hotelRows);
      } else {
        this.$message.warning("Preencha os viajantes.");
      }
    },
    dynamicRequiredField() {
      let flag = this.form.getFieldValue("contracted_services");

      if (flag != undefined) {
        flag = this.form.getFieldValue("contracted_services").includes("hotel");
      } else {
        flag = false;
      }

      return flag;
    },
  },
};
</script>
