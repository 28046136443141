var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-row',{attrs:{"gutter":[20, 0]}},[_c('a-col',{attrs:{"span":24}},[_c('h3',{staticClass:"title"},[_c('a-row',[_c('a-col',{attrs:{"span":8}},[_vm._v(" HOTEL "+_vm._s(_vm.hotelID))]),_vm._v(" "),_c('a-col',{staticClass:"a-right",attrs:{"span":8,"offset":8}},[(_vm.add && _vm.totalRows < 10)?_c('a-button',{attrs:{"size":"small","type":"link"},on:{"click":_vm.addHotel}},[_vm._v("\n              Adicionar hospedagem\n            ")]):_vm._e(),_vm._v(" "),(_vm.id == _vm.totalRows && _vm.totalRows > 1)?_c('a-button',{staticClass:"red",attrs:{"size":"small","type":"link"},on:{"click":_vm.removeHotel}},[_vm._v("\n              Remover\n            ")]):_vm._e()],1)],1)],1)]),_vm._v(" "),_c('a-col',{attrs:{"span":10}},[_c('a-form-item',[_c('label',{class:this.form.getFieldValue('hotel_' + _vm.hotelID + '_name')
              ? 'filled'
              : 'filled'},[_vm._v("Hotel / Resort\n          "),(
              _vm.tempContract[`hotel_${_vm.hotelID}_name_infotera`] &&
              !_vm.tempContract[`hotel_${_vm.hotelID}_name`]
            )?_c('font',{staticClass:"cgray",staticStyle:{"font-size":"9px"}},[_vm._v("\n            -\n            "+_vm._s(_vm.tempContract[`hotel_${_vm.hotelID}_name_infotera`])+"\n          ")]):_vm._e()],1),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `hotel_${_vm.hotelID}_name`,
            {
              rules: [
                {
                  required: _vm.dynamicRequiredField(),
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `hotel_${hotelID}_name`,\n            {\n              rules: [\n                {\n                  required: dynamicRequiredField(),\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"show-search":"","allow-clear":true,"placeholder":"Selecione o Hotel ou Resort","optionFilterProp":"txt"},on:{"change":_vm.onHotelSelect}},_vm._l((_vm.productSupplier.list),function(item,index){return _c('a-select-option',{key:index != undefined ? index : 0,attrs:{"txt":item.trading_name != undefined ? item.trading_name : '',"value":item.trading_name != undefined ? item.trading_name : ''}},[_vm._v("\n            "+_vm._s(item.trading_name)+"\n          ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_c('a-form-item',[_c('label',{class:this.form.getFieldValue(`hotel_${_vm.hotelID}_checkin`)
              ? 'filled'
              : ''},[_vm._v("Check-in")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
            'hotel_' + _vm.hotelID + '_checkin',
            {
              rules: [
                {
                  required: _vm.dynamicRequiredField(),
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'hotel_' + hotelID + '_checkin',\n            {\n              rules: [\n                {\n                  required: dynamicRequiredField(),\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Check-in","format":"DD/MM/YYYY","disabled-date":_vm.minDate,"showToday":false,"allowClear":false},on:{"change":_vm.selectedDate}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_c('a-form-item',[_c('label',{class:this.form.getFieldValue(`hotel_${_vm.hotelID}_checkout`)
              ? 'filled'
              : ''},[_vm._v("Check-out")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
            'hotel_' + _vm.hotelID + '_checkout',
            {
              rules: [
                {
                  required: _vm.dynamicRequiredField(),
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'hotel_' + hotelID + '_checkout',\n            {\n              rules: [\n                {\n                  required: dynamicRequiredField(),\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Check-out","format":"DD/MM/YYYY","showToday":false,"allowClear":false,"disabled-date":_vm.disabledEndDate,"open":_vm.checkoutOpen},on:{"openChange":_vm.checkoutOpenChange,"change":_vm.calcDaysInHotel}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":4}},[_c('a-form-item',[_c('label',{class:this.form.getFieldValue(`hotel_${_vm.hotelID}_rates`) ? 'filled' : ''},[_vm._v("Diárias")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'hotel_' + _vm.hotelID + '_rates',
            {
              rules: [
                {
                  required: _vm.dynamicRequiredField(),
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'hotel_' + hotelID + '_rates',\n            {\n              rules: [\n                {\n                  required: dynamicRequiredField(),\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"type":"number","placeholder":"Diárias"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_c('a-form-item',[_c('label',{class:this.form.getFieldValue(`hotel_${_vm.hotelID}_diet`)
              ? 'filled'
              : 'filled'},[_vm._v("Regime alimentar\n          "),(
              _vm.tempContract[`hotel_${_vm.hotelID}_diet_infotera`] &&
              !_vm.tempContract[`hotel_${_vm.hotelID}_diet`]
            )?_c('font',{staticClass:"cgray",staticStyle:{"font-size":"9px"}},[_vm._v("\n            -\n            "+_vm._s(_vm.tempContract[`hotel_${_vm.hotelID}_diet_infotera`])+"\n          ")]):_vm._e()],1),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `hotel_${_vm.hotelID}_diet`,
            {
              rules: [
                {
                  required: _vm.dynamicRequiredField(),
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `hotel_${hotelID}_diet`,\n            {\n              rules: [\n                {\n                  required: dynamicRequiredField(),\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"show-search":"","placeholder":"Regime alimentar"}},_vm._l((_vm.diets),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v("\n            "+_vm._s(item.name.toUpperCase())+"\n          ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_c('a-form-item',[_c('label',{class:this.form.getFieldValue(`hotel_${_vm.hotelID}_locator`)
              ? 'filled'
              : ''},[_vm._v("Localizador")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'hotel_' + _vm.hotelID + '_locator',
            {
              rules: [
                {
                  required: _vm.dynamicRequiredField(),
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'hotel_' + hotelID + '_locator',\n            {\n              rules: [\n                {\n                  required: dynamicRequiredField(),\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Localizador"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_c('a-form-item',[_c('label',{class:this.form.getFieldValue(`hotel_${_vm.hotelID}_total_price`)
              ? 'filled'
              : ''},[_vm._v("Valor total (R$)\n        ")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
            'hotel_' + _vm.hotelID + '_total_price',
            {
              rules: [
                {
                  required: _vm.dynamicRequiredField(),
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'hotel_' + hotelID + '_total_price',\n            {\n              rules: [\n                {\n                  required: dynamicRequiredField(),\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Valor total"},on:{"blur":_vm.emitValueUpdate}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1),_vm._v(" "),_vm._l((_vm.accommodationRows),function(accommodation){return _c('a-row',{key:accommodation.id,class:`accommodation accommodation-${accommodation.id}`,attrs:{"gutter":[15, 0]}},[_c('a-col',{attrs:{"span":_vm.$root.windowWidth > 900 ? 10 : 24}},[_c('a-col',{attrs:{"span":24}},[_c('h4',{staticClass:"title"},[_c('a-row',[_c('a-col',{staticClass:"text",attrs:{"span":12}},[_vm._v("\n              Acomodação #"+_vm._s(accommodation.id)+"\n            ")]),_vm._v(" "),_c('a-col',{staticClass:"a-right",attrs:{"span":12}},[(_vm.accommodationRows.length < 5 && accommodation.id === 1)?_c('a-button',{attrs:{"size":"small","type":"link"},on:{"click":function($event){return _vm.addAccommodation(_vm.accommodationRows.length)}}},[_vm._v("\n                Adicionar\n              ")]):_vm._e(),_vm._v(" "),(
                  accommodation.id === _vm.accommodationRows.length &&
                  _vm.accommodationRows.length > 1
                )?_c('a-button',{staticClass:"red",attrs:{"size":"small","type":"link"},on:{"click":function($event){return _vm.removeAccommodation(accommodation.id)}}},[_vm._v("\n                Remover\n              ")]):_vm._e()],1)],1)],1)]),_vm._v(" "),_c('a-col',{attrs:{"span":14}},[_c('a-form-item',[_c('label',{class:_vm.form.getFieldValue(
                'hotel_' + _vm.hotelID + '_accommodation_' + accommodation.id
              )
                ? 'filled'
                : ''},[_vm._v("Quarto")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'hotel_' + _vm.hotelID + '_accommodation_' + accommodation.id,

              {
                rules: [
                  {
                    required: _vm.dynamicRequiredField(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              'hotel_' + hotelID + '_accommodation_' + accommodation.id,\n\n              {\n                rules: [\n                  {\n                    required: dynamicRequiredField(),\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",attrs:{"placeholder":"Quarto"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":10}},[_c('a-form-item',[_c('label',{class:_vm.form.getFieldValue(
                'hotel_' +
                  _vm.hotelID +
                  '_accommodation_' +
                  accommodation.id +
                  '_type'
              )
                ? 'filled'
                : ''},[_vm._v("Tipo")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'hotel_' +
                _vm.hotelID +
                '_accommodation_' +
                accommodation.id +
                '_type',
              {
                rules: [
                  {
                    required: _vm.dynamicRequiredField(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              'hotel_' +\n                hotelID +\n                '_accommodation_' +\n                accommodation.id +\n                '_type',\n              {\n                rules: [\n                  {\n                    required: dynamicRequiredField(),\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",attrs:{"show-search":"","placeholder":"Tipo"}},_vm._l((_vm.accommodationTypes),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v("\n              "+_vm._s(item.name.toUpperCase())+"\n            ")])}),1)],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":_vm.$root.windowWidth > 900 ? 14 : 24}},[_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('h4',{staticClass:"title"},[_c('a-row',[_c('a-col',{attrs:{"span":12}},[_vm._v(" Viajantes ")]),_vm._v(" "),_c('a-col',{staticClass:"a-right",attrs:{"span":12}},[(
                    _vm.accommodationRows[accommodation.id - 1].travellers
                      .length < 10
                  )?_c('a-button',{attrs:{"size":"small","type":"link"},on:{"click":function($event){return _vm.addTraveller(
                      _vm.accommodationRows[accommodation.id - 1].travellers
                        .length,
                      accommodation.id
                    )}}},[_vm._v("\n                  Adicionar\n                ")]):_vm._e()],1)],1)],1)])],1),_vm._v(" "),_vm._l((_vm.accommodationRows[accommodation.id - 1]
          .travellers),function(traveller,index){return _c('a-row',{key:index,staticClass:"travellers-rows",attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":2}},[_c('font',{staticClass:"user"},[_c('a-icon',{attrs:{"type":"user"}}),_vm._v(" "),_c('font',{staticClass:"number"},[_vm._v("\n              "+_vm._s(traveller.id)+"\n            ")])],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":20}},[_c('a-form-item',{staticClass:"travel-input"},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                `hotel_${_vm.hotelID}_accommodation_${accommodation.id}_traveller_${traveller.id}`,
                {
                  rules: [
                    {
                      required: _vm.dynamicRequiredField(),
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]),expression:"[\n                `hotel_${hotelID}_accommodation_${accommodation.id}_traveller_${traveller.id}`,\n                {\n                  rules: [\n                    {\n                      required: dynamicRequiredField(),\n                      message: 'Obrigatório',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Selecione um viajante"}},_vm._l((_vm.theTravellers),function(traveller,index){return _c('a-select-option',{key:index,attrs:{"value":traveller.id}},[_vm._v("\n                "+_vm._s(traveller.name)+"\n                "+_vm._s(traveller.lastName)+"\n              ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":1}},[(
              traveller.id ==
                _vm.accommodationRows[accommodation.id - 1].travellers.length &&
              _vm.accommodationRows[accommodation.id - 1].travellers.length != 1
            )?_c('a-button',{attrs:{"size":"small","type":"danger","shape":"circle","icon":"delete"},on:{"click":function($event){return _vm.removeTraveller(traveller.id, accommodation.id)}}}):_vm._e(),_vm._v(" "),_c('a-tooltip',{attrs:{"placement":"topRight","title":"Adicionar todos viajantes"}},[(traveller.id == 1)?_c('a-button',{staticStyle:{"top":"5px"},attrs:{"size":"small","type":"primary","shape":"circle"},on:{"click":function($event){return _vm.addAllTravellers(accommodation.id)}}},[_c('a-icon',{staticStyle:{"font-size":"17px"},attrs:{"type":"usergroup-add"}})],1):_vm._e()],1)],1)],1)})],2)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }